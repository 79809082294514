<template>
	<div class="container">
		<div class="mb-2" style="text-align: left;">
			<select
				class="form-select"
				:value="tournamentId"
				@change="updateTournament"
				style="display: inline-block; width: auto"
			>
				<option
					v-for="tournament in tournaments"
					:key="tournament.tournamentId"
					:value="tournament.tournamentId"
				>{{ tournament.Name }}</option>
			</select>
		</div>
		<table class="table table-striped">
			<thead>
				<tr>
					<th style="text-align: left">Name</th>
					<th style="width: 200px">Category</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="golfer in golfers"
					:key="golfer.golferId"
				>
					<td style="text-align: left">
						<img v-if="golfer.countryCode"
							:src="'https://flagcdn.com/' + golfer.countryCode + '.svg'"
							:alt="golfer.country"
							width="27"
							style="margin-right: 8px; border: 1px solid lightgrey"
							:title="golfer.country"
						>
						<span>{{ golfer.golfer_name }}</span>
					</td>
					<td style="width: 200px">
						<select class="form-control form-control-sm" v-model="golfer.category">
							<option v-for="cat in categories" :key="cat" :value="cat.name">{{ cat.name }}</option>
						</select>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="row g-3">
			<div class="col-auto">
				<label for="secretCode" class="visually-hidden">Secret Code</label>
				<input class="form-control" id="secretCode" v-model="secretCode" type="text" placeholder="Password">
			</div>
			<div class="col-auto">
				<button class="btn btn-primary" type="button" @click="save">
					<span v-if="!loading">Save</span>
					<div v-else class="spinner-border" role="status">
					</div>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		golfers: [],
		categories: [],
		secretCode: window.localStorage.getItem('secret'),
		loading: false,
	}),
	computed: {
		tournaments() {
			return this.$store.state.tournaments;
		},
		tournamentId() {
			return this.$store.state.tournamentId;
		},
	},
	created() {
		if (!this.tournaments.length) {
			this.$store.dispatch('getTournaments');
		}
		if (this.tournamentId) {
			this.getGolfers();
			this.getCategories();
		}
	},
	watch: {
		tournamentId(val) {
			console.log('Tournament changed:', val);
			this.golfers = [];
			this.categories = [];
			this.getCategories();
			this.getGolfers();
		},
	},
	methods: {
		updateTournament(event) {
			this.$store.commit('setTournament', event.target.value);
		},
		getCategories() {
			fetch(`/api/v1/categories?tournamentId=${this.tournamentId}`)
				.then((res) => res.json())
				.then((categories) => {
					this.categories = categories;
				});
		},
		getGolfers() {
			// Retrieve all golfers any player has picked and their current scores
			fetch(`/api/v1/golfers/all?tournamentId=${this.tournamentId}`)
				.then((res) => res.json())
				.then((golfers) => {
					this.golfers = golfers;
				});
		},
		save() {
			this.loading = true;
			fetch(`/api/v1/golfers?tournamentId=${this.tournamentId}`, {
				method: 'PUT',
				body: JSON.stringify({ golfers: this.golfers, secretCode: this.secretCode }, null, 2),
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((res) => {
					if (res.status >= 400) {
						return res.text().then((error) => {
							throw new Error(error);
						});
					}
					window.localStorage.setItem('secret', this.secretCode);
					return res;
				})
				.then((res) => res.json())
				.then((response) => {
					this.loading = false;
					console.log(response);
				})
				.catch((error) => {
					console.error(error);
					this.loading = false;
				});
		},
	},
};
</script>
