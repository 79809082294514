import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import RegisterPlayer from '../views/RegisterPlayer.vue';
import EditGolfers from '../views/EditGolfers.vue';
// import EditScores from '../views/EditScores.vue';

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: {
			title: 'Masters Challenge',
		},
	}, {
		path: '/register',
		name: 'RegisterPlayer',
		component: RegisterPlayer,
		meta: {
			title: 'Register Player',
		},
	}, {
		path: '/golfers',
		name: 'EditGolfers',
		component: EditGolfers,
		meta: {
			title: 'Edit Golfers',
		},
	},
	// {
	// 	path: '/scores',
	// 	name: 'EditScores',
	// 	component: EditScores,
	// 	meta: {
	// 		title: 'Update Scores',
	// 	},
	// },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	console.log(to);
	document.title = to.meta.title;
	next();
});

export default router;
