import { createStore } from 'vuex';

export default createStore({
	state: {
		tournaments: [],
		tournamentId: null,
	},
	getters: {
	},
	mutations: {
		setTournament(state, tournamentId) {
			state.tournamentId = tournamentId;
		},
		setTournaments(state, tournaments) {
			state.tournaments = tournaments;
		},
	},
	actions: {
		getTournaments({ commit }) {
			return fetch('/api/v1/tournaments').then((res) => res.json())
				.then((ret) => {
					ret.sort((a, b) => b.espn_id - a.espn_id);
					const tournaments = ret.map((tournament) => ({ tournamentId: tournament.espn_id, Name: tournament.Name }));
					commit('setTournaments', tournaments);
					if (tournaments[0]) {
						commit('setTournament', tournaments[0].tournamentId);
					}
				});
		},
	},
	modules: {
	},
});
