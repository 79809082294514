<template>
	<div class="container">
		<form>
			<div class="mb-3">
				<label for="tournament" class="form-label" style="margin-right: 10px">
					Tournament
					<select class="form-select" :value="tournamentId" @input="updateTournament">
						<option
							v-for="tournament in tournaments"
							:key="tournament.tournamentId"
							:value="tournament.tournamentId"
						>{{ tournament.Name }}</option>
					</select>
				</label>
				<label for="playerName" class="form-label" style="margin-right: 10px">
					Player Name
					<input type="text" class="form-control" id="playerName" v-model="playerName">
				</label>
				<label for="tiebreaker" class="form-label" style="margin-right: 10px">
					Tiebreaker
					<input type="number" class="form-control" id="tiebreaker" v-model="tiebreaker">
				</label>
				<label for="secretCode" class="form-label">
					Secret Code
					<input type="text" class="form-control" id="secretCode" v-model="secretCode">
				</label>
			</div>
			<span style="margin-right: 10px">Select golfers below and then press submit.</span>
			<button type="button" class="btn btn-primary" :disabled="!canSubmit" @click="submit">
				<span v-if="!loading">Submit</span>
				<div v-else class="spinner-border" role="status">
				</div>
			</button>
			<h2>Golfers:</h2>
			<div class="row align-items-start">
				<div class="col golfer-column" v-for="(category, i) in categories" :key="category.name">
					<h3>{{ category.name }}</h3>
					<div class="form-check" v-for="golfer in golfers[category.key]" :key="golfer.golfer_id">
						<label class="form-check-label" :for="golfer.golfer_id">
							<img v-if="golfer.countryCode"
								:src="'https://flagcdn.com/' + golfer.countryCode + '.svg'"
								:alt="golfer.country"
								width="27"
								style="margin-right: 8px; border: 1px solid lightgrey"
								:title="golfer.country"
							>
							<span>{{ golfer.golfer_name }}</span>
						</label>
						<input class="form-check-input" type="radio" :name="'radio_' + category.key"
							:id="golfer.golfer_id"
							:value="golfer.golfer_id"
							v-model="choices[i]"
						>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	data: () => ({
		golfers: {},
		categories: [],
		playerName: '',
		tiebreaker: 0,
		secretCode: window.localStorage.getItem('secret'),
		choices: [],
		loading: false,
	}),
	computed: {
		canSubmit() {
			// console.log(Object.keys(this.choices));
			// console.log(Object.keys(this.choices).map((key) => this.choices[key]));
			// console.log(Object.keys(this.choices).every((key) => this.choices[key]));
			return this.choices.every((choice) => choice)
				&& this.playerName && this.secretCode;
		},
		tournaments() {
			return this.$store.state.tournaments;
		},
		tournamentId() {
			return this.$store.state.tournamentId;
		},
	},
	created() {
		if (!this.tournaments.length) {
			this.$store.dispatch('getTournaments');
		}
		if (this.tournamentId) {
			this.getCategories();
			this.getGolfers(this.tournamentId);
		}
	},
	watch: {
		tournamentId(id) {
			this.getGolfers(id);
			this.getCategories();
		},
	},
	methods: {
		updateTournament(event) {
			this.$store.commit('setTournament', event.target.value);
		},
		getCategories() {
			fetch(`/api/v1/categories?tournamentId=${this.tournamentId}`).then((res) => res.json())
				.then((categories) => {
					this.categories = categories.map((category) => ({
						...category,
						key: category.name.toLowerCase(),
					}));
					this.choices = [];
					categories.forEach(() => {
						this.choices.push(null);
					});
				});
		},
		getGolfers(tournamentId) {
			fetch(`/api/v1/golfers?tournamentId=${tournamentId}`).then((res) => res.json())
				.then((golfers) => {
					this.golfers = golfers;
					Object.keys(golfers).forEach((category) => {
						golfers[category].sort((a, b) =>
							a.golfer_name.split(' ').pop().localeCompare(b.golfer_name.split(' ').pop()));
					});
				});
		},
		submit() {
			this.loading = true;
			fetch('/api/v1/players', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					tournamentId: this.tournamentId,
					playerName: this.playerName,
					tiebreaker: this.tiebreaker,
					secretCode: this.secretCode,
					choices: this.choices.map((choice, i) => ({
						category: this.categories[i].name,
						golfer_id: choice,
					})),
				}),
			})
				.then((res) => {
					if (res.status >= 400) {
						return res.text().then((error) => {
							throw new Error(error);
						});
					}
					window.localStorage.setItem('secret', this.secretCode);
					return res;
				})
				.then((res) => res.json())
				.then((player) => {
					console.log(player);
					this.$router.push('/');
				})
				.catch((error) => {
					console.error(error);
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="less" scoped>
.golfer-column {
	text-align: left;
}
</style>
